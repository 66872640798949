<script setup lang="ts">
import { storeToRefs } from 'pinia'
import type { ListPaymentOptionsCreditPackage, ListPaymentOptionsSubscriptionDto } from '@swipe4work/api-client-fetch'
import { useUpgradeStore } from './upgrade-store'
import CostPerMatchCard from '@/pages/onboarding/components/CostPerMatchCard.vue'
import SubscriptionsCard from '@/pages/onboarding/components/SubscriptionsCard.vue'

definePage({
  path: '/upgrade/pakket',
  meta: {
    permissionResource: 'Lead',
  },
})

const upgradeStore = useUpgradeStore()
await upgradeStore.fill()
upgradeStore.fetchPaymentOptions()
const { creditPackage, creditPackages, jobSlotOptions, subscription, subscriptions } = storeToRefs(upgradeStore)
function prevPage() {
  upgradeStore.goToPage(0)
}

function selectCreditPackage(cp: ListPaymentOptionsCreditPackage) {
  creditPackage.value = cp
  subscription.value = undefined
  upgradeStore.goToPage(2)
}

function selectSubscription(sub: ListPaymentOptionsSubscriptionDto) {
  subscription.value = sub
  creditPackage.value = undefined
  upgradeStore.goToPage(2)
}
</script>

<template>
  <Card>
    <template #title>
      <h2>
        {{ $t("onboarding.subscription.title") }}
      </h2>
    </template>
    <template #subtitle>
      {{ $t("onboarding.subscription.description") }}
    </template>
    <template #content>
      <div class="min-h-[30rem]">
        <div flex="~ col xl:row" justify="center" space-x="6">
          <CostPerMatchCard :credit-packages="creditPackages" @next="selectCreditPackage" />
          <SubscriptionsCard :job-slot-options="jobSlotOptions" :subscriptions="subscriptions" @next="selectSubscription" />
        </div>
        <div mt="8" mx="auto" text="center" max-w="sm xl:2xl">
          {{ $t("onboarding.subscription.pricingFooter") }}
        </div>
      </div>
    </template>
    <template #footer>
      <div class="flex justify-between">
        <Button rounded outlined severity="secondary" icon="bi bi-arrow-left" @click="prevPage()" />
      </div>
    </template>
  </Card>
</template>
