<script setup lang="ts">
import NProgress from 'nprogress'

import { object, string } from 'zod'
import { storeToRefs } from 'pinia'
import { EmployerInviteType } from '@swipe4work/api-client-fetch'
import { useForm } from '@/modules/form'
import { useAuthStore } from '@/store'
import { useOnboardingStore } from '@/store/onboarding'
import { FirebaseAuthService } from '@/services/auth/firebase-auth-service'
import { AuthState } from '@/store/auth'

definePage({
  path: 'register',
  name: '/onboarding/register',
  meta: {
    layout: 'false',
  },
})
const authStore = useAuthStore()

const onboardingStore = useOnboardingStore()
const { employerOnboarding, hasInviteCode, invite, inviteType } = storeToRefs(onboardingStore)
if (employerOnboarding.value?.employer !== undefined) {
  // The user already as an account, so go to company page
  onboardingStore.router.replace({ name: '/onboarding/company' })
}

const registerModel = reactive({
  employerFirstName: invite.value?.firstName,
  employerLastName: invite.value?.lastName,
  email: invite.value?.email,
  password: '',
  confirmPassword: '',
})

const registerScheme = object({
  employerFirstName: string().min(1),
  employerLastName: string().min(1),
  password: string().min(1),
  confirmPassword: string().min(1),
})

const form = useForm(registerModel, registerScheme)
const router = useRouter()

const passwordsNotEqual = ref(false)

async function register() {
  const entity = form.validate()
  if (!entity) {
    return
  }
  passwordsNotEqual.value = entity.password !== entity.confirmPassword

  if (passwordsNotEqual.value) {
    return
  }

  NProgress.start()
  const response = await onboardingStore.createEmployer(
    {
      firstName: entity.employerFirstName,
      lastName: entity.employerLastName,
      password: entity.password,
    },
  )
  if (response) {
    try {
      await authStore.logout()
      const firebaseAuthService = await authStore.getAuthService<FirebaseAuthService>(FirebaseAuthService, authStore.firebaseAuth)
      await firebaseAuthService.loginWithToken(response.token)
    } catch (error) {
      authStore.setAuthState(AuthState.AuthenticationError)
    }
    await authStore.fetchEmployerAccess()
    if (invite.value?.type === EmployerInviteType.Teammate) {
      router.replace({ name: '/' })
    } else {
      onboardingStore.goToPage(1)
    }
  }
  NProgress.done()
}
</script>

<template>
  <Message v-if="inviteType === EmployerInviteType.Referral" severity="success" :closable="false" class="mx-auto">
    {{ $t("register.referralMessage", [invite?.invitedBy, invite?.invitedByCompany]) }}
  </Message>
  <Message v-if="inviteType === EmployerInviteType.Teammate" severity="success" :closable="false" class="mx-auto">
    {{ $t("register.teammateMessage", [invite?.invitedBy, invite?.invitedByCompany]) }}
  </Message>
  <Message v-if="!hasInviteCode" severity="warn" :closable="false" class="mx-auto">
    {{
      $t("register.inviteCodeRequired", { email: "info@swipe4work.com" })
    }}
  </Message>

  <main v-else>
    <form @submit.prevent="register">
      <Card>
        <template #title>
          <h2>
            {{ $t("register.welcome") }}
          </h2>
        </template>
        <template #content>
          <div class="min-h-[30rem]">
            <div>{{ $t("register.welcomeDescription") }}</div>

            <div class="mt-4">
              <label class="text-sm" for="email"> {{ $t("register.email") }} </label>
              <InputText
                id="email"
                v-model.trim="registerModel.email"
                class="w-100%"
                :disabled="true"
                :readonly="true"
                autocomplete="email"
              />
            </div>
            <div class="mt-4">
              <label for="employerFirstName"> {{ $t("settings.firstName") }}* </label>
              <InputText
                id="employerFirstName"
                v-model.trim="registerModel.employerFirstName"
                class="w-100%"
                required="true"
                autofocus
                :class="{ 'p-invalid': form.hasError('registerModel.employerFirstName') }"
              />
            </div>
            <div class="mt-4">
              <label for="employerLastName"> {{ $t("settings.lastName") }}* </label>
              <InputText
                id="employerLastName"
                v-model.trim="registerModel.employerLastName"
                class="w-100%"
                required="true"
                :class="{ 'p-invalid': form.hasError('registerModel.employerLastName') }"
              />
            </div>

            <div class="mt-4">
              <label class="block text-sm" for="password"> {{ $t("password") }} </label>
              <Password
                id="password"
                v-model="registerModel.password"
                toggle-mask
                :required="true"
                :feedback="false"

                :input-props="{ autocomplete: 'new-password', autofocus: true }"
                :class="{ 'p-invalid': form.hasError('registerModel.password') }"
              />
              <small v-if="passwordsNotEqual" class="color-red-700">{{ $t("register.passwordsNotEqual") }}</small>
            </div>
            <div class="mt-4">
              <label class="block text-sm" for="confirmPassword"> {{ $t("confirmPassword") }} </label>
              <Password
                id="confirmPassword"
                v-model="registerModel.confirmPassword"
                toggle-mask
                :required="true"
                :feedback="false"

                :input-props="{ autocomplete: 'new-password', autofocus: true }"
                :class="{ 'p-invalid': form.hasError('registerModel.confirmPassword') }"
              />
              <small v-if="passwordsNotEqual" class="color-red-700">{{ $t("register.passwordsNotEqual") }}</small>
            </div>
          </div>
        </template>
        <template #footer>
          <div class="flex justify-end">
            <Button
              v-click-async="register"
              rounded
              :label="$t('register.submit')"
            />
          </div>
        </template>
      </Card>
    </form>
  </main>
</template>
