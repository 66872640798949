<script setup lang="ts">
import { number, object, string } from 'zod'
import { useForm } from '@/modules/form'
import { useGeneralStore } from '@/store'
import { useOnboardingStore } from '@/store/onboarding'

definePage({
  path: 'company',
  name: '/onboarding/company',
  meta: {
    layout: 'false',
  },
})

const generalStore = useGeneralStore()
const onboardingStore = useOnboardingStore()

// fetch the payment options once the employer has an account, otherwise an error occurs
onboardingStore.fetchPaymentOptions()

const t = useI18n()

const company = reactive({
  name: '',
  locationName: '',
  geolocation: { latitude: 0, longitude: 0 },
  employeeCount: {
    lower: undefined,
    upper: undefined,
  },
})

const companyScheme = object({
  name: string().min(1, `${t.t('companyName')} ${t.t('isRequired')}`),
  locationName: string().min(1, `${t.t('mainLocation')} ${t.t('isRequired')}`),
  geolocation: object({
    latitude: number(),
    longitude: number(),
  }),
  employeeCount: object({
    lower: number({ required_error: `${t.t('employeeCount')} ${t.t('isRequired')}` }),
    upper: number().optional(),
  }),
})
const companyForm = useForm(company, companyScheme)

async function nextPage() {
  const companyEntity = companyForm.validate()
  if (companyEntity) {
    onboardingStore.companyModel = companyEntity
    onboardingStore.goToPage(2)
  }
}
</script>

<template>
  <Card>
    <template #title>
      <h2>
        {{ $t("createCompany") }}
      </h2>
    </template>
    <template #content>
      <div space-y-4 class="lg:min-h-[30rem]">
        <p class="font-light">
          {{ $t("onboarding.company.description") }}
        </p>
        <div flex="~ col" items="start" gap="2">
          <label for="companyName"> {{ $t("companyName") }}* </label>
          <InputText
            id="companyName"
            v-model.trim="company.name"
            class="lg:min-w-sm max-lg:min-w-full"
            required="true"
            autofocus
            :class="companyForm.hasError('name') ? 'p-invalid' : ''"
          />
          <small v-if="companyForm.hasError('name')" class="color-red-700">{{ companyForm.errorMessage("name") }}</small>
        </div>
        <div flex="~ col" items="start" gap="2">
          <label for="locationName"> {{ $t("onboarding.company.locationLabel") }}* </label>
          <div class="w-100% flex justify-between">
            <LocationInput
              v-model:location-name="company.locationName"
              v-model:geolocation="company.geolocation"
              class="lg:min-w-sm max-lg:min-w-full"
              :is-invalid="companyForm.hasError('locationName')"
            />
          </div>
          <small v-if="companyForm.hasError('locationName')" class="color-red-700">{{ companyForm.errorMessage("locationName") }}</small>
        </div>
        <div flex="~ col" items="start" gap="2">
          <label for="name"> {{ $t("employeeCount") }}* </label>
          <Select
            v-model="company.employeeCount"
            class="lg:min-w-sm max-lg:min-w-full"
            :options="generalStore.employeeCountOptions"
            option-value="id"
            option-label="name"
            :required="true"
            scroll-height="400px"
            :class="companyForm.hasError('employeeCount.lower') ? 'p-invalid' : ''"
          />
          <small v-if="companyForm.hasError('employeeCount.lower')" class="color-red-700">{{
            companyForm.errorMessage("employeeCount.lower")
          }}</small>
        </div>
      </div>
    </template>
    <template #footer>
      <div class="flex justify-end">
        <Button rounded icon="bi bi-arrow-right" label="Volgende" @click="nextPage()" />
      </div>
    </template>
  </Card>
</template>
