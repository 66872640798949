<script setup lang="ts">
import { storeToRefs } from 'pinia'
import { useJobStore } from './jobs/job-store'
import { videoMediaUrls } from '@/store/user-store'
import { useAuthStore, useDashboardStore } from '@/store'
import { useTopbarStore } from '@/components/app/topbar-store'
import { useCompanyStore } from '@/store/company'
import type { IntroductionStepProps } from '@/components/cards/IntroductionProcessStep.vue'

definePage({
  meta: {
    permissionResource: 'Dashboard',
  },
})

const t = useI18n()
const router = useRouter()
const dashboardStore = useDashboardStore()
const authStore = useAuthStore()
const { isConferenceUser, isLead } = storeToRefs(authStore)
const { statistics, unhandledJobs } = storeToRefs(dashboardStore)

const { companyComplete } = storeToRefs(useCompanyStore())
const { hasJobs } = storeToRefs(useJobStore())

const showIntroductionCard = computed(() => companyComplete.value === false || hasJobs.value === false)
const showLeadIntroduction = computed(() => statistics.value?.totalMatchCount === 0 && isLead.value)

const topbarStore = useTopbarStore()
topbarStore.setAndClear({
  breadcrumbs: [{ label: t.t('routes.dashboard') }],
})

const conferenceSteps = computed((): IntroductionStepProps[] => {
  return [
    {
      isCompleted: true,
      iconClass: {
        'bi bi-play-btn-fill': true,
        '!\color-gray-700': true,
      },
      onButtonClick: () => {},
      buttonLabel: 'bekijk video',
      title: 'Video bekijken',
      description: 'Leer snel en eenvoudig hoe Swipe4Work werkt',
    },
    {
      isCompleted: companyComplete.value ?? false,
      iconClass: {
        'pi pi-building': true,
        '!\color-gray-700': true,
      },
      onButtonClick: () => router.push({ name: '/company/', query: { next: '/jobs/create/' } }),
      buttonLabel: 'Aanmaken',
      title: 'Bedrijfsprofiel aanmaken',
      description: 'Maak je profiel aantrekkelijk voor kandidaten',
    },
    {
      isCompleted: hasJobs.value ?? false,
      iconClass: {
        'bi bi-card-text': true,
        '!\color-gray-700': true,
      },
      onButtonClick: () => router.push({ name: '/jobs/create' }),
      buttonLabel: 'Aanmaken',
      title: 'Zet je eerste gratis vacature live!',
      description: 'Trek gratis kandidaten aan tijdens én na het evenement!',
    },
  ]
})

const leadSteps = computed((): IntroductionStepProps[] => {
  return [
    {
      isCompleted: true,
      iconClass: {
        'bi bi-play-btn-fill': true,
        '!\color-gray-700': true,
      },
      onButtonClick: () => {},
      buttonLabel: 'bekijk video',
      title: 'Video bekijken',
      description: 'Leer snel en eenvoudig waarom Swipe4Work jouw bedrijf kan helpen',
    },
    {
      isCompleted: unhandledJobs.value.length === 0,
      iconClass: {
        'pi pi-user': true,
        '!\color-gray-700': true,
      },
      onButtonClick: () => router.push({ name: '/job-applications' }),
      buttonLabel: 'Kandidaten',
      title: 'Kandidaten beoordelen',
      description: 'Beoordeel de openstaande kandiaten en vind de perfecte match',
    },
  ]
})
</script>

<template>
  <!-- only show introduction for conference user. In the future we can easily show it for all users. -->
  <IntroductionCard
    v-if="isConferenceUser && showIntroductionCard"
    :video-src="videoMediaUrls.conferenceOnboarding"
    :steps-title="$t('conference.introduction.steps.title')"
    :title="$t('conference.introduction.title')"
    :subtitle="$t('conference.introduction.subtitle')"
    :steps="conferenceSteps"
  />
  <IntroductionCard
    v-else-if="showLeadIntroduction"
    :video-src="videoMediaUrls.leads"
    :steps-title="$t('leads.introduction.steps.title')"
    :title="$t('leads.introduction.title')"
    :subtitle="$t('leads.introduction.subtitle')"
    :steps="leadSteps"
  />
  <Dashboard v-else-if="statistics" :statistics="statistics" />
  <div v-else>
    <Skeleton width="33#" height="10rem" />
  </div>
</template>
