/* tslint:disable */
/* eslint-disable */
/**
 * Swipe4Work Employer API
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 0.1.1
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */
/**
 * Check if a given object implements the GetCompanyHireReportInfoResult interface.
 */
export function instanceOfGetCompanyHireReportInfoResult(value) {
    if (!('isFirstReport' in value) || value['isFirstReport'] === undefined)
        return false;
    if (!('hasMatches' in value) || value['hasMatches'] === undefined)
        return false;
    return true;
}
export function GetCompanyHireReportInfoResultFromJSON(json) {
    return GetCompanyHireReportInfoResultFromJSONTyped(json, false);
}
export function GetCompanyHireReportInfoResultFromJSONTyped(json, ignoreDiscriminator) {
    if (json == null) {
        return json;
    }
    return {
        'firstDay': json['firstDay'] == null ? undefined : (new Date(json['firstDay'])),
        'isFirstReport': json['isFirstReport'],
        'hasMatches': json['hasMatches'],
    };
}
export function GetCompanyHireReportInfoResultToJSON(json) {
    return GetCompanyHireReportInfoResultToJSONTyped(json, false);
}
export function GetCompanyHireReportInfoResultToJSONTyped(value, ignoreDiscriminator = false) {
    if (value == null) {
        return value;
    }
    return {
        'firstDay': value['firstDay'] == null ? undefined : (value['firstDay'].toISOString().substring(0, 10)),
        'isFirstReport': value['isFirstReport'],
        'hasMatches': value['hasMatches'],
    };
}
