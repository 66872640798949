import type { RouteLocationNormalized, RouteLocationNormalizedLoaded, RouteLocationRaw } from 'vue-router'
import type { Logger } from '@/modules/logger'
import type { useAuthStore } from '@/store'

/**
 * Type for the guard result when it's not an redirect to a route
 * Why? So it's clear what the guard result is and should do, old implementation was not clear, confusing and too vague
 *
 * Skip: Skip all guards and proceed to the requested route
 * Next: Proceed to the next guard
 */
export enum GuardResultType {
  Skip = 'skip',
  Next = 'next',
}

/**
 * Type for the guard result
 *
 * RouteLocationRaw: Redirect to the route
 * GuardResultType: Skip or Next
 */
export type GuardResult = RouteLocationRaw | GuardResultType

/**
 * Abstract class for the guard
 *
 * A guard is a class that checks if the user is allowed to access a route
 * For maintenance purposes, the guards are separated into different classes
 */
export abstract class Guard {
  protected logger: Logger
  protected to: RouteLocationNormalized
  protected from: RouteLocationNormalizedLoaded
  protected authStore: ReturnType<typeof useAuthStore>

  constructor(
    logger: Logger,
    to: RouteLocationNormalized,
    from: RouteLocationNormalizedLoaded,
    authStore: ReturnType<typeof useAuthStore>,
  ) {
    this.logger = logger
    this.to = to
    this.from = from
    this.authStore = authStore
  }

  /**
   * Abstract property to define the guard label
   * @protected
   */
  protected abstract label: string

  /**
   * Abstract method to check the guard
   *
   * For clean code, it can return void, which means it will proceed to the next guard
   * @protected
   */
  protected abstract check(to: RouteLocationNormalized): Promise<GuardResult | void>

  /**
   * Format the result to a human-readable string for logging
   * @param result
   */
  private formatResult = (result: GuardResult) => {
    switch (result) {
      case GuardResultType.Skip:
        return 'Skip'
      case GuardResultType.Next:
        return 'Next'
      default:
        if (typeof result === 'object' && 'name' in result) {
          return result.name
        }

        return 'Unknown'
    }
  }

  /**
   * Resolve the guard, implementation in auth-guard module
   */
  async resolve() {
    this.logger.debug(`Checking guard`)

    // Check the guard, if no result is returned, proceed to the next guard
    const result = await this.check(this.to) ?? GuardResultType.Next

    this.logger.debug(`Completed with result: ${this.formatResult(result)}`)

    return result
  }
}
