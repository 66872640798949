<script setup lang="ts">
import { useAuthStore } from '@/store'

const authStore = useAuthStore()
const router = useRouter()

const action = async () => {
  await authStore.logout()

  await router.replace({ name: '/login' })
}

// Since we say that no role and permissions is an employee, we should redirect back when there are permissions
if (authStore.permissions.length > 0) {
  router.replace({ name: '/' })
}

definePage({
  name: '/employee',
  path: '/employee',
  meta: {
    allowAnonymous: false,
  },
})
</script>

<template>
  <AuthLayoutCard>
    <template #title>
      {{ $t("auth.employee.title") }}
    </template>
    <template #message>
      {{ $t("auth.employee.message") }}
    </template>
    <section class="flex flex-col items-center justify-center">
      <Button v-click-async="action" :label="$t('logout')" rounded />
    </section>
  </AuthLayoutCard>
</template>
