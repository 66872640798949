<script setup lang="ts">
definePage({
  name: '/settings/TermsAndConditions',
  path: '/instellingen/algemene-voorwaarden',
  meta: {
    layout: 'false',
  },
})
</script>

<template>
  {{ $t("termsAndConditions.description") }}
  <a target="_blank" class="underline" :href="$t('termsAndConditions.url')">{{ $t("termsAndConditions.url") }}</a>
</template>
