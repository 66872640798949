<script setup lang="ts">
import { storeToRefs } from 'pinia'
import Button from 'primevue/button'
import { useConfirm } from 'primevue/useconfirm'
import { nativeEnum, number, object, string } from 'zod'
import type { DefaultMessageDto } from '@swipe4work/api-client-fetch'
import { DefaultMessageType } from '@swipe4work/api-client-fetch'
import { useForm } from '@/modules/form'
import { useCompanyStore } from '@/store/company'

definePage({
  name: '/settings/DefaultMessages',
  path: '/instellingen/standaard-berichten',
  meta: {
    layout: 'false',
  },
})

const t = useI18n()

const confirm = useConfirm()
const companyStore = useCompanyStore()

companyStore.fetchCompany()

const { company } = storeToRefs(companyStore)

const messageTypeOptions = Object.keys(DefaultMessageType).map(key => ({
  label: t.t(`settings.defaultMessages.types.${key}.label`),
  value: key,
  description: t.t(`settings.defaultMessages.types.${key}.description`),
}))

const baseModel = {
  id: undefined as number | undefined,
  messageType: DefaultMessageType.StartMatch as DefaultMessageType,
  title: '',
  body: '',
}

const model = reactive({ ...baseModel })

const form = useForm(model, object({
  id: number().optional(),
  messageType: nativeEnum(DefaultMessageType),
  title: string().min(1),
  body: string().min(1),
}))

const defaultMessageDialogVisible = ref(false)

async function submitDefaultMessage() {
  const entity = form.validate()
  if (form.isValid && entity !) {
    const success = await companyStore.addOrUpdateDefaultMessage(entity)
    if (success) {
      hideDialog()

      await companyStore.fetchCompany()
    }
  }
}

function deleteDefaultMessage(defaultMessageId: number) {
  confirm.require({
    header: t.t('confirm'),
    message: t.t('settingsPage.confirmDeleteDefaultMessage'),
    acceptLabel: t.t('yes'),
    rejectLabel: t.t('no'),
    accept: async () => {
      await companyStore.deleteDefaultMessage(defaultMessageId)
      await companyStore.fetchCompany()
    },
  })
}

function showDefaultMessageDialog(defaultMessage?: DefaultMessageDto) {
  form.reset()

  defaultMessageDialogVisible.value = true

  model.id = defaultMessage?.id ?? undefined
  model.messageType = defaultMessage?.messageType ?? DefaultMessageType.StartMatch
  model.title = defaultMessage?.title ?? ''
  model.body = defaultMessage?.body ?? ''
}

function hideDialog() {
  defaultMessageDialogVisible.value = false
}

function truncateWithEllipsis(text: string, maxLength: number) {
  if (text.length > maxLength) {
    return `${text.substr(0, maxLength)}...`
  }
  return text
}
</script>

<template>
  <Message class="my-2">
    {{ $t('settings.defaultMessages.info.banner') }}
  </Message>
  <DataTable :value="company?.defaultMessages" responsive-layout="scroll">
    <template #empty>
      <EmptyMessage>
        {{ $t('settings.defaultMessages.table.empty') }}
      </EmptyMessage>
    </template>
    <Column field="title" :header="$t('title')">
      <template #body="defaultMessage">
        <PopoverHover :enabled="defaultMessage.data.title.length > 10" max-width="10vw">
          <template #icon>
            {{ truncateWithEllipsis(defaultMessage.data.title, 10) }}
          </template>
          {{ defaultMessage.data.title }}
        </PopoverHover>
      </template>
    </Column>
    <Column field="body" :header="$t('message')">
      <template #body="defaultMessage">
        <PopoverHover :enabled="defaultMessage.data.body.length > 30" max-width="30vw">
          <template #icon>
            {{ truncateWithEllipsis(defaultMessage.data.body, 30) }}
          </template>
          {{ defaultMessage.data.body }}
        </PopoverHover>
      </template>
    </Column>
    <Column field="messageType" :header="$t('settings.defaultMessages.form.messageType.label')">
      <template #body="defaultMessage">
        <span :title="defaultMessage.data.messageType">
          {{ $t(`settings.defaultMessages.types.${defaultMessage.data.messageType}.label`) }}
        </span>
      </template>
    </Column>
    <Column>
      <template #header>
        <div class="flex justify-end">
          <Button
            icon="bi bi-plus-lg"
            outlined
            rounded
            size="small"
            @click="showDefaultMessageDialog()"
          />
        </div>
      </template>
      <template #body="defaultMessage">
        <div class="flex gap-x-2">
          <Button
            v-if="!defaultMessage.data.isPublic"
            outlined rounded
            size="small"
            icon="bi bi-pencil"
            @click="showDefaultMessageDialog(defaultMessage.data)"
          />
          <Button
            v-if="!defaultMessage.data.isPublic"
            outlined rounded
            size="small"
            icon="bi bi-trash"
            @click="deleteDefaultMessage(defaultMessage.data.id)"
          />
        </div>
      </template>
    </Column>
  </DataTable>

  <!-- Add Default Message Dialog -->
  <Dialog
    v-model:visible="defaultMessageDialogVisible"
    :modal="true"
    :header="$t('settings.defaultMessages.form.title')"
    class="min-w-2xl"
    :closable="false"
    :dismissable-mask="true"
    @hide="hideDialog"
  >
    <div class="mb-6">
      <label for="reportingPeriodicity">{{ $t("settings.defaultMessages.form.messageType.label") }}</label>
      <Select
        v-model="model.messageType"
        :options="messageTypeOptions"
        option-value="value"
        option-label="label"
        class="w-100%"
        scroll-height="400px"
        :class="{ 'p-invalid': form.hasError('messageType') }"
      >
        <template #option="slotProps">
          <div class="flex items-center">
            <PopoverHover class="mr-2">
              {{ slotProps.option.description }}
            </PopoverHover>
            <span>{{ slotProps.option.label }}</span>
          </div>
        </template>
      </Select>
      <small v-if="form.hasError('title')" class="color-red-700">{{ form.errorMessage("messageType") }}</small>
    </div>
    <div class="mb-6">
      <label for="title"> {{ $t("title") }} </label>
      <InputText
        id="title"
        v-model.trim="model.title"
        class="w-100%"
        required
        :class="{ 'p-invalid': form.hasError('title') }"
      />
      <small v-if="form.hasError('title')" class="color-red-700">{{ form.errorMessage("title") }}</small>
    </div>
    <div>
      <label for="message"> {{ $t("message") }} </label>
      <Textarea
        id="message"
        v-model.trim="model.body"
        class="w-100%"
        rows="10"
        :auto-resize="true"
        required
        :class="{ 'p-invalid': form.hasError('body') }"
      />
      <small v-if="form.hasError('body')" class="color-red-700">{{ form.errorMessage("body") }}</small>
    </div>

    <template #footer>
      <Button :label="$t('cancel')" variant="outlined" @click="hideDialog" />
      <Button v-click-async="submitDefaultMessage" :label="$t('save')" autofocus />
    </template>
  </Dialog>
</template>
