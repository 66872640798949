import { routes } from 'vue-router/auto-routes'
import { setupLayouts } from 'virtual:generated-layouts'
import { createApp } from 'vue'

import { createRouter, createWebHistory } from 'vue-router'
import { VueFire, VueFireAuthWithDependencies } from 'vuefire'
import Toast, { POSITION } from 'vue-toastification'
import 'vue-toastification/dist/index.css'
import { browserPopupRedirectResolver, indexedDBLocalPersistence } from 'firebase/auth'
import { initializeApp } from 'firebase/app'
import { createHead } from '@unhead/vue'
import type { EmployerRole, PermissionLevel, PermissionResource } from '@swipe4work/api-client-fetch'
import { VueQueryPlugin } from '@tanstack/vue-query'
import App from './App.vue'
import 'virtual:uno.css'

import type { UserModule } from './types'
import Vue3LottiePlugin from '@/modules/vue3lottie'
import type { AuthProvider } from '@/services/auth/auth-service'
import pinia from '@/modules/pinia'
import HttpClients from '@/modules/http-clients'
import clickAsync from '@/directives/click-async'

const firebaseConfig = {
  apiKey: import.meta.env.VITE_APP_FIREBASE_API_KEY,
  authDomain: import.meta.env.VITE_APP_FIREBASE_AUTH_DOMAIN,
  projectId: import.meta.env.VITE_APP_FIREBASE_PROJECT_ID,
  storageBucket: import.meta.env.VITE_APP_FIREBASE_STORAGE_BUCKET,
  messagingSenderId: import.meta.env.VITE_APP_FIREBASE_MESSAGE_SENDER_ID,
  appId: import.meta.env.VITE_APP_FIREBASE_APP_ID,
}

declare module 'vue-router' {
  interface RouteMeta {
    allowAnonymous?: boolean
    allowedRoles?: EmployerRole[]
    permissionResource?: PermissionResource
    permissionLevel?: PermissionLevel
    requiredAuthProvider?: AuthProvider

    layout?: string | boolean
  }
}

init()

async function init() {
  const app = createApp(App)

  // directives
  app.directive('click-async', clickAsync)

  app.use(HttpClients)

  // Use separate Toast package
  app.use(Toast, {
    position: POSITION.BOTTOM_RIGHT,
    shareAppContext: true,
    timeout: 10000,
  })

  app.use(createHead())
  app.use(Vue3LottiePlugin)

  app.use(VueFire, {
    // imported above but could also just be created here
    firebaseApp: initializeApp(firebaseConfig),
    modules: [
      VueFireAuthWithDependencies({
        dependencies: {
          popupRedirectResolver: browserPopupRedirectResolver,
          persistence: indexedDBLocalPersistence,
        },
      }),
    ],
  })

  const router = createRouter({
    routes: setupLayouts(routes),
    history: createWebHistory(import.meta.env.BASE_URL),
  })
  router.onError((error, to) => {
    if (
      error.message.includes('Failed to fetch dynamically imported module')
      || error.message.includes('Importing a module script failed')
      || error.message.includes('error loading dynamically imported module')
    ) {
      console.info('Het portaal is ge-update')
      window.location.href = to.fullPath
    }
  })
  app.use(router)

  app.use(pinia)

  app.use(VueQueryPlugin, {
    enableDevtoolsV6Plugin: true,
    queryClientConfig: {
      defaultOptions: {
        queries: {
          refetchOnWindowFocus: false,
        },
      },
    },
  })

  // Install modules in modules/ folder
  Object.values(import.meta.glob<{ install: UserModule }>('./modules/*.ts', { eager: true })).forEach(i => i.install?.(app, router))
  app.mount('#app')
}
