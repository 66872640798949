<script setup lang="ts">
import type { FirebaseError } from '@firebase/util'
import type { Ref } from 'vue'
import { object, string } from 'zod'
import { storeToRefs } from 'pinia'
import { useToast } from 'vue-toastification'
import { useAuthStore } from '@/store'
import { useForm } from '@/modules/form'
import { useUserStore } from '@/store/user-store'
import { FirebaseAuthService } from '@/services/auth/firebase-auth-service'
import { AuthProvider } from '@/services/auth/auth-service'

definePage({
  name: '/settings/Account',
  path: '/instellingen/account',
  meta: {
    layout: 'false',
    requiredAuthProvider: AuthProvider.Firebase,
  },
})

const authStore = useAuthStore()
const userStore = useUserStore()
const toast = useToast()
const t = useI18n()

const { profile } = storeToRefs(userStore)
const model = reactive({
  currentPassword: '',
  newPassword: '',
  confirmNewPassword: '',
})

const scheme = object({
  currentPassword: string().min(1),
  newPassword: string().min(1),
  confirmNewPassword: string().min(1),
})

const form = useForm(model, scheme)
const firebaseError: Ref<FirebaseError | undefined | null> = ref(undefined)
const isInvalidPassword = computed(() => firebaseError?.value?.code == 'auth/wrong-password')
const isWeakPassword = computed(() => firebaseError?.value?.code == 'auth/weak-password')
const formFilled = computed(() => model.newPassword != '' && model.currentPassword != '' && model.confirmNewPassword != '')

const passwordsNotEqual = ref(false)

async function updatePassword() {
  const firebaseAuthService = await authStore.getAuthService(FirebaseAuthService)

  form.submitting.value = true
  firebaseError.value = undefined
  const entity = form.validate()
  if (!entity) {
    form.submitting.value = false
    return
  }
  passwordsNotEqual.value = entity.newPassword != entity.confirmNewPassword

  if (passwordsNotEqual.value) {
    form.submitting.value = false
    return
  }

  const email = profile.value?.email
  if (!email) {
    toast.error(t.t('updatePassword.invalidEmail'))
    return
  }

  try {
    await firebaseAuthService.updatePassword(email, entity.currentPassword, entity.newPassword)
  } catch (error) {
    firebaseError.value = error as FirebaseError
  }

  if (firebaseError.value == null) {
    toast.success(t.t('updatePassword.success'))
  }

  form.submitting.value = false
}
</script>

<template>
  <form class="space-y-4" @submit.prevent="updatePassword">
    <div class="mt-4">
      <input id="email" type="text" class="hidden" name="email" autocomplete="username" :value="profile?.email">
      <div>
        <label for="current-password"> {{ $t("updatePassword.currentPassword") }} </label>
        <InputText
          id="current-password"
          v-model="model.currentPassword"
          class="w-100%"
          required="true"
          type="password"
          autocomplete="current-password"
          autofocus
          :class="{ 'p-invalid': form.hasError('currentPassword') }"
        />
      </div>
    </div>

    <div>
      <label for="new-password"> {{ $t("updatePasswordModel.newPassword") }} </label>
      <Password
        id="new-password"
        v-model="model.newPassword"
        toggle-mask
        input-class="w-100%"
        class="w-100%"
        :input-props="{ autocomplete: 'new-password', autofocus: true }"
        :required="true"
        :class="{ 'p-invalid': form.hasError('newPassword') }"
      />
      <small v-if="passwordsNotEqual" class="color-red-700">{{ $t("updatePassword.passwordsNotEqual") }}</small>
    </div>
    <div>
      <label for="confirmNewPassword"> {{ $t("updatePassword.confirmNewPassword") }} </label>
      <Password
        id="confirmNewPassword"
        v-model="model.confirmNewPassword"
        input-class="w-100%"
        class="w-100%"
        toggle-mask
        :required="true"
        :input-props="{ autocomplete: 'new-password', autofocus: true }"
        :class="{ 'p-invalid': form.hasError('confirmNewPassword') }"
      />
      <small v-if="passwordsNotEqual" class="color-red-700">{{ $t("updatePassword.passwordsNotEqual") }}</small>
    </div>
    <Button
      :disabled="!formFilled || form.submitting.value"
      rounded
      :label="$t('updatePassword.submit')"
      @click="updatePassword"
    />
    <div v-if="isInvalidPassword" class="color-red-700">
      {{ $t("updatePassword.invalidPassword") }}
    </div>
    <div v-if="isWeakPassword" class="color-red-700">
      {{ $t("updatePassword.weakPassword") }}
    </div>
  </form>
</template>

<style scoped lang="scss">
:deep(.w-100\%) {
  @apply w-100%;
}
</style>
