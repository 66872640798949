<script setup lang="ts">
import { storeToRefs } from 'pinia'
import { EmployerRole } from '@swipe4work/api-client-fetch'
import logo from '@/assets/logo.png'
import { useConferenceOnboardingStore } from '@/store/conference-onboarding'
import Popover from 'primevue/popover'

definePage({
  name: '/conference/onboarding',
  path: '/promotiedagen/onboarding',
  meta: {
    layout: 'empty',
    allowedRoles: [EmployerRole.ConferenceOnboarding],
  },
  redirect: { name: '/conference.onboarding/introduction' },
})

const conferenceOnboardingStore = useConferenceOnboardingStore()
const { items } = storeToRefs(conferenceOnboardingStore)
await conferenceOnboardingStore.fill()

const accountManagerPanel = ref<InstanceType<typeof Popover> | null>(null)

function openPanel(event: Event) {
  accountManagerPanel.value?.toggle(event)
}
const route = useRoute()
const router = useRouter()

const activeIndex = ref(0)
watch(() => route.name, () => {
  activeIndex.value = items.value.findIndex(item => item.to.name === route.name)
}, { immediate: true })
</script>

<template>
  <div class="h-screen w-full flex justify-center">
    <div class="h-full flex flex-col justify-between 2xl:w-1/2 max-xl:w-full xl:w-2/3">
      <div class="h-full min-h-screen flex flex-col justify-between">
        <img
          :src="logo" class="w-50 lg:(m-x-16 m-t-8 w-65) max-lg:m-x-8 max-lg:m-t-4"
          alt="logo"
          @click="router.push({ name: '/conference.onboarding/introduction' })"
        >
        <section class="max-w-2xl flex flex-col gap-y-2 lg:m-x-16 lg:m-y-4 max-lg:m-x-8 max-lg:m-y-2">
          <router-view />
        </section>
        <div class="flex items-center gap-x-4 bg-background p-x-8 p-y-4">
          <Steps :model="items" :readonly="true" :active-step="activeIndex" class="flex-grow" />
          <Popover ref="accountManagerPanel">
            <div class="p-2">
              <ContactAccountManagerCard />
            </div>
          </Popover>
          <Button outlined rounded @click="openPanel">
            {{ $t('conference.onboarding.stepper.helpLabel') }}
          </Button>
        </div>
      </div>
    </div>
    <div class="relative h-auto flex items-center max-xl:hidden 2xl:w-1/2 xl:w-1/3">
      <img :src="items[activeIndex].backgroundImage" class="h-full w-full object-cover">
    </div>
  </div>
</template>
