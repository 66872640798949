/* tslint:disable */
/* eslint-disable */
/**
 * Swipe4Work Employer API
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 0.1.1
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */
import { GetJobApplicationUsageStatisticsFromJSON, GetJobApplicationUsageStatisticsToJSON, } from './GetJobApplicationUsageStatistics.js';
import { SkillDtoFromJSON, SkillDtoToJSON, } from './SkillDto.js';
import { EducationExperienceDtoFromJSON, EducationExperienceDtoToJSON, } from './EducationExperienceDto.js';
import { WorkExperienceDtoFromJSON, WorkExperienceDtoToJSON, } from './WorkExperienceDto.js';
import { NullableRangeDtoFromJSON, NullableRangeDtoToJSON, } from './NullableRangeDto.js';
import { RangeDtoFromJSON, RangeDtoToJSON, } from './RangeDto.js';
/**
 * Check if a given object implements the GetJobApplicationAnonymousUser interface.
 */
export function instanceOfGetJobApplicationAnonymousUser(value) {
    if (!('userId' in value) || value['userId'] === undefined)
        return false;
    if (!('cityName' in value) || value['cityName'] === undefined)
        return false;
    if (!('skills' in value) || value['skills'] === undefined)
        return false;
    if (!('topSkills' in value) || value['topSkills'] === undefined)
        return false;
    if (!('yearsExperience' in value) || value['yearsExperience'] === undefined)
        return false;
    if (!('hoursPerWeek' in value) || value['hoursPerWeek'] === undefined)
        return false;
    if (!('educationLevel' in value) || value['educationLevel'] === undefined)
        return false;
    if (!('personalCharacteristics' in value) || value['personalCharacteristics'] === undefined)
        return false;
    if (!('personalityTypeId' in value) || value['personalityTypeId'] === undefined)
        return false;
    if (!('hasCompleteProfile' in value) || value['hasCompleteProfile'] === undefined)
        return false;
    if (!('educationExperiences' in value) || value['educationExperiences'] === undefined)
        return false;
    if (!('noEducationExperience' in value) || value['noEducationExperience'] === undefined)
        return false;
    if (!('workExperiences' in value) || value['workExperiences'] === undefined)
        return false;
    if (!('noWorkExperience' in value) || value['noWorkExperience'] === undefined)
        return false;
    if (!('workExperienceDescription' in value) || value['workExperienceDescription'] === undefined)
        return false;
    if (!('workExperienceComplete' in value) || value['workExperienceComplete'] === undefined)
        return false;
    if (!('ambitions' in value) || value['ambitions'] === undefined)
        return false;
    if (!('personalInterests' in value) || value['personalInterests'] === undefined)
        return false;
    if (!('usageStatistics' in value) || value['usageStatistics'] === undefined)
        return false;
    return true;
}
export function GetJobApplicationAnonymousUserFromJSON(json) {
    return GetJobApplicationAnonymousUserFromJSONTyped(json, false);
}
export function GetJobApplicationAnonymousUserFromJSONTyped(json, ignoreDiscriminator) {
    if (json == null) {
        return json;
    }
    return {
        'userId': json['userId'],
        'cityName': json['cityName'],
        'skills': (json['skills'].map(SkillDtoFromJSON)),
        'topSkills': (json['topSkills'].map(SkillDtoFromJSON)),
        'yearsExperience': NullableRangeDtoFromJSON(json['yearsExperience']),
        'hoursPerWeek': RangeDtoFromJSON(json['hoursPerWeek']),
        'educationLevel': json['educationLevel'],
        'personalCharacteristics': json['personalCharacteristics'],
        'personalityTypeId': json['personalityTypeId'],
        'hasCompleteProfile': json['hasCompleteProfile'],
        'educationExperiences': (json['educationExperiences'].map(EducationExperienceDtoFromJSON)),
        'noEducationExperience': json['noEducationExperience'],
        'workExperiences': (json['workExperiences'].map(WorkExperienceDtoFromJSON)),
        'noWorkExperience': json['noWorkExperience'],
        'workExperienceDescription': json['workExperienceDescription'],
        'workExperienceComplete': json['workExperienceComplete'],
        'ambitions': json['ambitions'],
        'personalInterests': json['personalInterests'],
        'newJobReason': json['newJobReason'] == null ? undefined : json['newJobReason'],
        'usageStatistics': GetJobApplicationUsageStatisticsFromJSON(json['usageStatistics']),
    };
}
export function GetJobApplicationAnonymousUserToJSON(json) {
    return GetJobApplicationAnonymousUserToJSONTyped(json, false);
}
export function GetJobApplicationAnonymousUserToJSONTyped(value, ignoreDiscriminator = false) {
    if (value == null) {
        return value;
    }
    return {
        'userId': value['userId'],
        'cityName': value['cityName'],
        'skills': (value['skills'].map(SkillDtoToJSON)),
        'topSkills': (value['topSkills'].map(SkillDtoToJSON)),
        'yearsExperience': NullableRangeDtoToJSON(value['yearsExperience']),
        'hoursPerWeek': RangeDtoToJSON(value['hoursPerWeek']),
        'educationLevel': value['educationLevel'],
        'personalCharacteristics': value['personalCharacteristics'],
        'personalityTypeId': value['personalityTypeId'],
        'educationExperiences': (value['educationExperiences'].map(EducationExperienceDtoToJSON)),
        'noEducationExperience': value['noEducationExperience'],
        'workExperiences': (value['workExperiences'].map(WorkExperienceDtoToJSON)),
        'noWorkExperience': value['noWorkExperience'],
        'workExperienceDescription': value['workExperienceDescription'],
        'workExperienceComplete': value['workExperienceComplete'],
        'ambitions': value['ambitions'],
        'personalInterests': value['personalInterests'],
        'newJobReason': value['newJobReason'],
        'usageStatistics': GetJobApplicationUsageStatisticsToJSON(value['usageStatistics']),
    };
}
