import type { ErrorContext } from '@swipe4work/api-client-fetch'
import { Configuration } from '@swipe4work/api-client-fetch'
import { useToast } from 'vue-toastification'
import { captureException } from '@sentry/vue'
import { AuthState, useAuthStore } from '@/store/auth'

enum ErrorType {
  ServerNotReachable,
  Unauthorized,
  Forbidden,
  NotFound,
  BadRequest,
  InternalServerError,
  Unknown,
}

function determineErrorHandling(error: ErrorContext) {
  if (error.error instanceof TypeError) {
    return ErrorType.ServerNotReachable
  }

  const status = error.response?.status
  switch (status) {
    case 401:
      return ErrorType.Unauthorized
    case 403:
      return ErrorType.Forbidden
    case 404:
      return ErrorType.NotFound
    case 400:
      return ErrorType.BadRequest
    case 500:
      return ErrorType.InternalServerError
    default:
      return ErrorType.Unknown
  }
}

/**
 * Fetch configuration with middleware to add Authorization header
 */
export const apiClientFetchConfiguration = () => {
  return new Configuration({
    basePath: import.meta.env.VITE_APP_API_URL,
    middleware: [
      {
        async pre(context) {
          const accessToken = await useAuthStore().getAccessToken()

          if (!accessToken) {
            return context
          }

          return {
            url: context.url,
            init: {
              ...context.init,
              headers: new Headers({
                ...context.init.headers,
                Authorization: `Bearer ${accessToken}`,
              }),
            },
          }
        },
        async onError(context) {
          const toast = useToast()
          const authStore = useAuthStore()

          const errorType = determineErrorHandling(context)
          switch (errorType) {
            case ErrorType.Unauthorized:
              toast.error('Het lijkt erop dat je niet langer bent ingelogd. Log opnieuw in om door te gaan.')
              break
            case ErrorType.Forbidden:
              toast.error('Je hebt geen toegang tot deze pagina.')
              break
            case ErrorType.ServerNotReachable:
              authStore.setAuthState(AuthState.ServerNotReachable)
              break
            case ErrorType.BadRequest:
            case ErrorType.InternalServerError:
              toast.error('Er is een fout opgetreden. Probeer het later opnieuw.')
              captureException(context.error)
              break
            case ErrorType.Unknown:
              authStore.setAuthState(AuthState.UnexpectedError)
              captureException(context.error)
              break
          }

          return Promise.reject(context.error)
        },
      },
    ],
  })
}
