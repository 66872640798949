/* tslint:disable */
/* eslint-disable */
/**
 * Swipe4Work Employer API
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 0.1.1
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */
import { ListJobsInPortalJobDtoFromJSON, ListJobsInPortalJobDtoToJSON, } from './ListJobsInPortalJobDto.js';
/**
 * Check if a given object implements the ListJobsInPortalResult interface.
 */
export function instanceOfListJobsInPortalResult(value) {
    if (!('count' in value) || value['count'] === undefined)
        return false;
    if (!('totalCount' in value) || value['totalCount'] === undefined)
        return false;
    if (!('jobs' in value) || value['jobs'] === undefined)
        return false;
    return true;
}
export function ListJobsInPortalResultFromJSON(json) {
    return ListJobsInPortalResultFromJSONTyped(json, false);
}
export function ListJobsInPortalResultFromJSONTyped(json, ignoreDiscriminator) {
    if (json == null) {
        return json;
    }
    return {
        'count': json['count'],
        'totalCount': json['totalCount'],
        'jobs': (json['jobs'].map(ListJobsInPortalJobDtoFromJSON)),
    };
}
export function ListJobsInPortalResultToJSON(json) {
    return ListJobsInPortalResultToJSONTyped(json, false);
}
export function ListJobsInPortalResultToJSONTyped(value, ignoreDiscriminator = false) {
    if (value == null) {
        return value;
    }
    return {
        'count': value['count'],
        'totalCount': value['totalCount'],
        'jobs': (value['jobs'].map(ListJobsInPortalJobDtoToJSON)),
    };
}
