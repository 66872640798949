<script setup lang="ts">
import { storeToRefs } from 'pinia'
import { useSettingsStore } from './settings-store'
import InviteTeammateDialog from './components/InviteTeammateDialog.vue'
import { useCompanyStore } from '@/store/company'

definePage({
  name: '/settings/Teammates',
  path: '/instellingen/teamgenoten',
  meta: {
    layout: 'false',
  },
})

const companyStore = useCompanyStore()
const { company } = storeToRefs(companyStore)
const settingsStore = useSettingsStore()
const { employers } = storeToRefs(settingsStore)

const showInviteTeammateDialog = ref(false)

async function openInviteTeammateDialog() {
  showInviteTeammateDialog.value = true
}

function closeInviteTeammateDialog() {
  showInviteTeammateDialog.value = false
}
</script>

<template>
  <DataTable :value="employers" responsive-layout="scroll">
    <template #empty>
      <EmptyMessage>
        {{ $t('settings.teammates.table.empty', { companyName: company?.name }) }}
      </EmptyMessage>
    </template>
    <Column field="name" :header="$t('name')" />
    <Column field="email" :header="$t('email')" />
    <Column>
      <template #header>
        <div class="flex justify-end">
          <Button
            v-tooltip.left="$t('settings.teammates.inviteInfo')"
            icon="bi bi-plus-lg"
            outlined rounded
            @click="openInviteTeammateDialog"
          />
        </div>
      </template>
    </Column>
  </DataTable>
  <InviteTeammateDialog :visible="showInviteTeammateDialog" @close="closeInviteTeammateDialog" />
</template>
