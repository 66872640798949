import type { PrimeVueConfiguration } from 'primevue/config'
import PrimeVue from 'primevue/config'
import ToastService from 'primevue/toastservice'
import DialogService from 'primevue/dialogservice'
import ConfirmationService from 'primevue/confirmationservice'

import Aura from '@primevue/themes/aura'
import { definePreset } from '@primevue/themes'
import Ripple from 'primevue/ripple'
import type { UserModule } from '@/types'

// Guide to setup design tokens: https://github.com/orgs/primefaces/discussions/2078#discussioncomment-10919773
const designTokens = {
  message: {
    colorScheme: {
      light: {
        info: {
          color: '{primary.700}',
        },
      },
    },
  },
}

export const install: UserModule = (app) => {
  const MyPreset = definePreset(Aura, {
    primitive: {
      indigo: {
        50: '#f2f2ff',
        100: '#e6e6ff',
        200: '#bfbfff',
        300: '#9999ff',
        400: '#4d4dff',
        500: '#151558',
        600: '#12124c',
        700: '#0e0e40',
        800: '#0b0b33',
        900: '#080827',
        950: '#070722',
      },
      orange: {
        50: ' #fffdf5',
        100: ' #fff9e6',
        200: ' #fff2bf',
        300: ' #ffe999',
        400: ' #ffd94d',
        500: ' #f7b500',
        600: ' #d99d00',
        700: ' #b98a00',
        800: ' #997600',
        900: '#806300',
      },
      teal: {
        50: '#f0f9f8',
        100: '#d5f0eb',
        200: '#b9e6de',
        300: '#9ddcd1',
        400: '#82d2c4',
        500: '#abd3cc',
        600: '#5eb7a9',
        700: '#4b9a8c',
        800: '#387d6f',
        900: '#265f52',
      },
    },
    semantic: {
      primary: {
        50: '{indigo.50}',
        100: '{indigo.100}',
        200: '{indigo.200}',
        300: '{indigo.300}',
        400: '{indigo.400}',
        500: '{indigo.500}',
        600: '{indigo.600}',
        700: '{indigo.700}',
        800: '{indigo.800}',
        900: '{indigo.900}',
        950: '{indigo.950}',
      },
      form: {
        field: {
          padding: {
            y: '0.75rem',
            x: '1rem',
          },
        },
      },
      button: {
        sm: {
          padding: {
            y: '0.75rem',
            x: '1rem',
          },
        },
        lg: {
          padding: {
            y: '0.75rem',
            x: '1rem',
          },
        },
      },
    },
    components: {
      ...designTokens,
      radiobutton: {
        checkedBackground: 'white',
        checkedBorderColor: '{blue.500}',
        icon: {
          checkedColor: '{blue.500}',
        },
      },
    },
  })
  const config: PrimeVueConfiguration = {
    theme: {
      preset: MyPreset,
      options: {
        darkModeSelector: '.app-dark',
        cssLayer: {
          name: 'primevue',
          order: 'tailwind-base, primevue, tailwind-utilities',
        },
      },
    },
    ripple: true,
    pt: {
      confirmdialog: {
        pcRejectButton: {
          root: {
            class: 'p-button-outlined',
          },
        },
      },
    },
  }
  app.use(ToastService)
  app.use(DialogService)
  app.use(ConfirmationService)
  app.use(DialogService)
  app.directive('ripple', Ripple)
  app.use(PrimeVue, config)
}
