import type { GetEmployerProfileResult } from '@swipe4work/api-client-fetch'

import { defineStore } from 'pinia'
import posthog from 'posthog-js'

export const videoMediaUrls = {
  leads: 'https://player.vimeo.com/progressive_redirect/playback/904368655/rendition/720p/file.mp4?loc=external&signature=857487476eae5cdaca5bc4624c420e35ac02dc27ca79794f9048b0f9fb20d84e',
  conferenceOnboarding: 'https://player.vimeo.com/progressive_redirect/playback/1016952508/rendition/720p/file.mp4?loc=external&signature=752a5ff414499231c0f875ffdd8df969f7e6a819f49073548d6bd3a0b337d243',
}

export const useUserStore = defineStore('user', {
  state: () => ({
    profile: undefined as GetEmployerProfileResult | undefined,
  }),
  getters: {
    isOriginalCompany: (state) => {
      return state.profile?.originalCompanyId === undefined || state.profile?.companyId === state.profile?.originalCompanyId
    },
    hasSubscription: state => state.profile?.hasSubscription,
  },
  actions: {
    async fetchProfile() {
      try {
        const profileResponse = await this.http.employerApi.getProfile()
        this.profile = profileResponse
        if (this.profile) {
          posthog.identify(this.profile.externalId)
          posthog.group('company', `company:${this.profile.companyId}`, { name: this.profile.companyName })
        }
      } catch (error) {
        console.error(error)
      }
      return this.profile
    },
    async logout() {
      this.profile = undefined
      posthog.reset()
    },
  },
})
