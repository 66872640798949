<script setup lang="ts">
import { ReportingPeriodicity } from '@swipe4work/api-client-fetch'
import { storeToRefs } from 'pinia'
import { useToast } from 'vue-toastification'

import { nativeEnum, object, string } from 'zod'
import { useSettingsStore } from './settings-store'
import { useForm } from '@/modules/form'
import { useUserStore } from '@/store/user-store'

definePage({
  name: '/settings/UserProfile',
  path: '/instellingen/gebruikersprofiel',
  meta: {
    layout: 'false',
  },
})

const settingsStore = useSettingsStore()
const userStore = useUserStore()
const t = useI18n()
const toast = useToast()

const profileScheme = object({
  firstName: string().min(1, `${t.t('settings.firstName')} ${t.t('isRequired')}`),
  lastName: string().min(1, `${t.t('settings.lastName')} ${t.t('isRequired')}`),
  reportingPeriodicity: nativeEnum(ReportingPeriodicity),
})
const { profile } = storeToRefs(userStore)

const profileModel = reactive({
  firstName: profile.value?.firstName ?? '',
  lastName: profile.value?.lastName ?? '',
  reportingPeriodicity: profile.value?.reportingPeriodicity ?? ReportingPeriodicity.None,
  email: profile.value?.email ?? '',
})

watch(profile, (state) => {
  if (state) {
    profileModel.firstName = state.firstName
    profileModel.lastName = state.lastName
    profileModel.reportingPeriodicity = state.reportingPeriodicity
    profileModel.email = state.email
  }
})

const reportingPeriodicityOptions = Object.keys(ReportingPeriodicity).map(key => ({
  label: t.t(`${key.toLocaleLowerCase()}`),
  value: key,
}))

const form = useForm(profileModel, profileScheme)
const hasChanges = computed(
  () =>
    profile.value?.firstName != profileModel.firstName
    || profile.value?.lastName != profileModel.lastName
    || profile.value?.reportingPeriodicity != profileModel.reportingPeriodicity,
)

async function updateProfile() {
  const entity = await form.validate()
  form.submitting.value = true
  if (entity != null) {
    await settingsStore.updateProfile(entity)
    toast.success(t.t('settings.changesSaved'))
    await userStore.fetchProfile()
  }
  form.submitting.value = false
}
</script>

<template>
  <div class="space-y-4">
    <div class="mt-4">
      <label for="firstName"> {{ $t("settings.firstName") }} </label>
      <InputText
        id="firstName"
        v-model.trim="profileModel.firstName"
        class="w-100%"
        required="true"
        autofocus
        :class="{ 'p-invalid': form.hasError('firstName') }"
      />
      <small v-if="form.hasError('firstName')" class="color-red-700">{{ form.errorMessage("firstName") }}</small>
    </div>
    <div>
      <label for="lastName"> {{ $t("settings.lastName") }} </label>
      <InputText
        id="lastName"
        v-model.trim="profileModel.lastName"
        class="w-100%"
        required="true"
        :class="{ 'p-invalid': form.hasError('lastName') }"
      />
      <small v-if="form.hasError('firstName')" class="color-red-700">{{ form.errorMessage("firstName") }}</small>
    </div>
    <div>
      <label for="email"> {{ $t("email") }}</label>
      <div class="font-light">
        {{ profileModel.email }}
      </div>
    </div>
    <div>
      <label for="reportingPeriodicity"> {{ $t("reportingPeriodicity") }} </label>
      <Select
        v-model="profileModel.reportingPeriodicity"
        :options="reportingPeriodicityOptions"
        option-value="value"
        option-label="label"
        class="w-100%"
        scroll-height="400px"
        :class="{ 'p-invalid': form.hasError('reportingPeriodicity') }"
      />
    </div>
    <Button :disabled="!hasChanges || form.submitting.value" rounded @click="updateProfile">
      {{ $t("submit") }}
    </Button>
  </div>
</template>
