/* tslint:disable */
/* eslint-disable */
/**
 * Swipe4Work Employer API
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 0.1.1
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */
import { HasHiresFromJSON, HasHiresToJSON, } from './HasHires.js';
import { HelpNeededFromJSON, HelpNeededToJSON, } from './HelpNeeded.js';
/**
 * Check if a given object implements the CreateCompanyHireReportCommandDto interface.
 */
export function instanceOfCreateCompanyHireReportCommandDto(value) {
    if (!('firstDay' in value) || value['firstDay'] === undefined)
        return false;
    if (!('hasHires' in value) || value['hasHires'] === undefined)
        return false;
    if (!('hireCount' in value) || value['hireCount'] === undefined)
        return false;
    return true;
}
export function CreateCompanyHireReportCommandDtoFromJSON(json) {
    return CreateCompanyHireReportCommandDtoFromJSONTyped(json, false);
}
export function CreateCompanyHireReportCommandDtoFromJSONTyped(json, ignoreDiscriminator) {
    if (json == null) {
        return json;
    }
    return {
        'firstDay': (new Date(json['firstDay'])),
        'hasHires': HasHiresFromJSON(json['hasHires']),
        'hireCount': json['hireCount'],
        'helpNeeded': json['helpNeeded'] == null ? undefined : HelpNeededFromJSON(json['helpNeeded']),
    };
}
export function CreateCompanyHireReportCommandDtoToJSON(json) {
    return CreateCompanyHireReportCommandDtoToJSONTyped(json, false);
}
export function CreateCompanyHireReportCommandDtoToJSONTyped(value, ignoreDiscriminator = false) {
    if (value == null) {
        return value;
    }
    return {
        'firstDay': ((value['firstDay']).toISOString().substring(0, 10)),
        'hasHires': HasHiresToJSON(value['hasHires']),
        'hireCount': value['hireCount'],
        'helpNeeded': HelpNeededToJSON(value['helpNeeded']),
    };
}
